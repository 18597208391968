/* To select which google fonts you want, go to https://fonts.google.com, select every font and variants required bu the app, and select the @import way */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  .primary-button {
    @apply px-3.5 py-1 outline outline-1 rounded-full active:border-totalRed text-totalRed active:bg-totalRed text-white bg-totalRed font-medium text-[13px];
    &:disabled {
      @apply bg-gray-300 text-gray-500;
    }
  }
  .secondary-button {
    @apply px-3.5 py-1 outline outline-1 border-totalRed text-totalRed active:bg-totalRed active:text-white rounded-full bg-transparent font-medium text-[13px];
  }
}

html,
body,
div#__next {
  background-color: #f7f9fa;
  margin: 0;
}

/* To make  Banner trick work properly without showing x-scrollbar*/
html,
body {
  overflow-x: hidden;
}

div[role='tooltip'] > .MuiTooltip-tooltip {
  font-size: 0.725rem;
}

.MuiAccordion-overwrite:before {
  display: block !important;
}
